import { useEffect, useState } from 'react';

import { isBrowser } from '../helpers';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: isBrowser() ? window.innerWidth : 0,
    height: isBrowser() ? window.innerHeight : 0,
  });

  const changeWindowSize = () => {
    if (isBrowser()) {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }
  };

  useEffect(() => {
    if (isBrowser()) {
      window.addEventListener('resize', changeWindowSize);

      return () => {
        window.removeEventListener('resize', changeWindowSize);
      };
    }
  }, []);

  return windowSize;
};

export default useWindowSize;
