import * as styles from '../styles/teachers.module.scss';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import React from 'react';
import ScrollToTopButton from '../components/ScrollToTopButton/ScrollToTopButton';
import SectionTitle from '../components/SectionTitle';
import Seo from '../components/SEO';
import TextReadMore from '../components/TextReadMore/TextReadMore';
import AnimateIn from '../components/AnimateIn';
import useWindowSize from '../hooks/useWindowSize';

const Teachers = ({ data }) => {
  const teachers = data.teachers.nodes;

  const { width: windowWidth } = useWindowSize();

  return (
    <Layout>
      <Seo title={'Pilart - Staff'} description={'Conosci il nostro fantastico staff!'} />
      <SectionTitle title="CONOSCI GLI INSEGNANTI" />
      <div className={styles.teachers}>
        {teachers.map((teacher, index) => {
          const featuredImage = getImage(teacher.frontmatter?.featuredImg?.childImageSharp?.gatsbyImageData);

          return (
            <AnimateIn slideUp={windowWidth < 576} distance={30} key={teacher.frontmatter.slug}>
              <div className={styles.teacher} id={teacher.frontmatter.slug}>
                <h2 className={styles.teacherName}>
                  {teacher.frontmatter.name} {teacher.frontmatter.surname}
                </h2>
                <div className={styles.imageWrapper}>
                  <GatsbyImage image={featuredImage} alt={teacher.frontmatter?.title} className={styles.image} />
                </div>
                <div className={styles.description}>
                  <TextReadMore content={teacher.frontmatter.description} tabIndex={index} />
                </div>
              </div>
            </AnimateIn>
          );
        })}
      </div>
      <ScrollToTopButton />
    </Layout>
  );
};

export default Teachers;

export const query = graphql`
  query TeachersPage {
    teachers: allMarkdownRemark(
      sort: { fields: frontmatter___order, order: ASC }
      filter: { frontmatter: { type: { eq: "teacher" } } }
    ) {
      nodes {
        frontmatter {
          type
          title
          slug
          name
          surname
          description
          featuredImg {
            childImageSharp {
              gatsbyImageData(width: 300, height: 300, quality: 100, placeholder: BLURRED, formats: AUTO)
            }
          }
        }
        id
      }
    }
  }
`;
