import * as styles from './text-read-more.module.scss';

import React, { useState } from 'react';

import useWindowSize from '../../hooks/useWindowSize';

const TextReadMore = ({
  content = '',
  limit = 300,
  readmore = '... Espandi',
  close = 'Chiudi',
  callback = () => {},
  tabIndex = 0,
}) => {
  const [visible, setVisible] = useState(false);
  const { width: windowWidth } = useWindowSize();

  const handleClick = () => {
    setVisible(!visible);

    callback();
  };

  if (!content) {
    return null;
  }

  if (windowWidth >= 576) {
    return <div className={styles.wrapper}>{content}</div>;
  }

  return (
    <div className={styles.wrapper}>
      {content?.substring(0, limit)}
      <span className={visible ? styles.visible : styles.hidden}>{visible && content?.substring(limit, content?.length)}</span>
      &nbsp;
      <span className={styles.readmoreText} onClick={handleClick} onKeyDown={handleClick} role="button" tabIndex={tabIndex}>
        {visible ? close : readmore}
      </span>
    </div>
  );
};

export default TextReadMore;
